.banner-container {
    padding: 0px 16px;
    margin-top: 16px;
}

.swiper {
    width: 100%;
    aspect-ratio: 16 / 9;
    border-radius: 16px;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}