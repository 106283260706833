@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";

.activity-container {
    display: flex;
    flex-direction: column;
}

.title-containe {
    font-size: 20px;
    font-weight: bold;
    color: #000;
}

.list-container {
    display: flex;
    flex-direction: column;
    padding: 8px 0px;
}

.item-container {
    padding: 0 16px;
    font-size: 16px;
    display: flex;
    flex-direction: row;
}

.line-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.circle-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    /* 透明圆的尺寸 */
    height: 18px;
    /* 透明圆的尺寸 */
}

.solid-circle {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #0DAE28;
    border-radius: 50%;
}

.transparent-circle {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(70, 255, 101, 0.19);
    border-radius: 50%;
}

.line {
    width: 1px;
    height: 100%;
    background-color: #0DAE28;
}

.body-container {
    display: flex;
    flex-direction: column;
}

.date-container {
    font-size: 12px;
    color: #666;
    margin-left: 12px;
    margin-right: 12px;
    height: 18px;
    align-items: center;
    display: flex;
}

.content-container {
    margin-top: 6px;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    background-color: #F2F2F2;
    border-radius: 12px;
    padding: 12px;
}

.activity-title-container {
    font-size: 18px;
    font-weight: bold;
    color: #000;
    padding: 0;
    margin: 0;
}

.subTitle-container {
    margin-top: 12px;
    font-size: 14px;
    color: #787878;
}

.paginated-container {
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}