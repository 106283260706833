.song-container {
  display: flex;
  flex-direction: column;
}

.title-container {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  padding-left: 16px;
  font-size: 20px;
  color: #000;
  font-weight: bold;
  align-items: center;
}

.more-container {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
  margin-left: auto;
  margin-right: 16px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  padding: 6px 16px;
}

.img-container {
  width: 100%;
  aspect-ratio: 109 / 156;
  object-fit: cover;
  border-radius: 12px;
}

.song-title-container {
  margin: 2px;
  font-size: 17px;
  color: #000;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}