.appContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 480px;
  /* 最大宽度为 480px */
  margin: 0 auto;
  /* 居中对齐 */
  width: 100%;
  /* 默认宽度 */
  background-color: #fff;
  /* 可选背景色 */
  min-height: 100vh;
  /* 确保占满屏幕高度 */
}

.swiperContainer {
  width: 100vw - 32px;
  height: calc((100vw - 32px) * 9 / 16);
  background-color: blue;
  margin: 16px;
  border-radius: 16px;
}

.nameContainer {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.bioContainer {
  font-size: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
}

.numberContainer {
  display: flex;
  align-items: center;
  justify-self: center;
}

.numberContainer2 {
  display: flex;
  align-items: center;
  justify-self: center;
}

.supportBtnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.suppertBtn {
  width: 200px;
  margin-top: 16px;
  background-color: #09A7FF;
  height: 48px;
  border-radius: 48px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 15px;
  font-weight: bold;
  color: #FFF;
}

.flip-numbers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
}

.flip-numbers-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  margin: 0 5px;
}

.countContainer {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 8px;
}

.supportContainer {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 8px;
}